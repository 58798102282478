import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Container,
  Divider,
  TextField,
  Stack,
  Typography,
  Box,
} from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

import { auth as api } from '@codesass/api';

import { useDispatch, useSelector } from 'react-redux';

import { showFlashMessage } from 'modules/ui/slice';

import { selectProfile } from 'modules/auth/selectors';

import { updateProfile } from 'modules/auth/slice';

import Layout from './Layout';

const schema = yup
  .object({
    username: yup.string().required('กรุณาระบุชื่อผู้ใช้งาน'),
    name: yup
      .string()
      .matches(
        /^(|(นาย|นาง|นางสาว)[ก-๏]+\s[ก-๏]+)$/,
        'ห้ามใส่เว้นวรรคหลังคำนำหน้า และชื่อกับนามสกุลให้เว้นวรรคหนึ่งครั้งโดยข้อมูลทั้งหมดต้องเป็นตัวอักษรภาษาไทย'
      ),
    email: yup.string().email().required('กรุณาระบุอีเมล์'),
    password: yup.string().matches(/.{8,}/, {
      excludeEmptyString: true,
      message: 'รหัสผ่านต้องมีความยาวขั้นต่ำ 8 ตัวอักษร',
    }),
    avatar: yup
      .mixed<FileList>()
      .test('fileSize', 'ไฟล์มีขนาดเกิน 100 KB', files => {
        if (!files) return true;

        return files.length > 0 && files[0]
          ? files[0].size <= 100 * 1_024
          : true;
      }),
  })
  .required();

type ProfileForm = {
  username: string;
  name?: string | undefined;
  email: string;
  password?: string | undefined;
  avatar?: FileList | undefined;
};

const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const previewAvatarRef = useRef<HTMLImageElement | null>(null);
  const avatarRef = useRef<HTMLInputElement | null>(null);
  const [avatarImage, setAvatarImage] = useState<string>();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: profile
      ? {
          username: profile.username ?? '',
          name: profile.name,
          email: profile.email,
          password: '',
        }
      : {
          username: '',
          name: '',
          email: '',
          password: '',
        },
  });
  const { ref: avatarFieldRef, ...avatar } = register('avatar');
  const previewAvatar = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await avatar.onChange(event);

      const avatarFile = event.target.files?.[0];

      if (!avatarFile) return;

      setAvatarImage(URL.createObjectURL(avatarFile));

      previewAvatarRef.current?.addEventListener('load', () => {
        avatarImage && URL.revokeObjectURL(avatarImage);
      });
    },
    [avatar, avatarImage]
  );

  const openAvatarUploader = useCallback(() => {
    avatarRef?.current?.click();
  }, []);

  const onSubmit = handleSubmit(async (form: ProfileForm) => {
    try {
      dispatch(updateProfile({ ...form, avatar: form.avatar?.[0] }));
    } catch (error) {
      if (error instanceof api.UpdateProfileError) {
        setError(
          error.field,
          { type: 'focus', message: error.message },
          { shouldFocus: true }
        );
      }
    }
  });

  const verifyEmail = useCallback(async () => {
    try {
      await api.sendEmailVerification();
      dispatch(
        showFlashMessage({
          type: 'success',
          message:
            'โปรดตรวจสอบอีเมล์ของคุณและดำเนินการยืนยันอีเมล์ตามขั้นตอนดังปรากฏในอีเมล์นั้น',
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          showFlashMessage({
            type: 'error',
            message: error.message,
          })
        );
      }
    }
  }, [dispatch]);

  return (
    <Layout>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <form noValidate onSubmit={onSubmit}>
          <Stack gap={2}>
            <Typography variant="h5" component="h1" textAlign="center">
              แก้ไขบัญชีผู้ใช้งาน
            </Typography>
            <Box width={100} height={100} mx="auto">
              {profile?.avatar || avatarImage ? (
                <Box
                  component="img"
                  ref={previewAvatarRef}
                  src={avatarImage || profile?.avatar}
                  width="100%"
                  height="100%"
                  alt="รูปประจำตัว"
                  sx={{ objectFit: 'contain' }}
                ></Box>
              ) : (
                <StaticImage
                  src="../../../assets/images/no-avatar.png"
                  alt="รูปประจำตัว"
                />
              )}
            </Box>
            <Typography variant="caption">
              ขนาดรูปภาพที่เหมาะสมคือ 100 x 100 px
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              onClick={openAvatarUploader}
            >
              อัพโหลดรูปประจำตัว
            </Button>
            <Box
              component="input"
              type="file"
              visibility="hidden"
              {...avatar}
              ref={(e: HTMLInputElement) => {
                avatarFieldRef(e);
                avatarRef.current = e;
              }}
              onChange={previewAvatar}
            />
            {errors.avatar?.message}
            <TextField
              label="ชื่อผู้ใช้งาน"
              placeholder="ชื่อผู้ใช้งาน"
              {...register('username')}
              error={Boolean(errors.username)}
              helperText={
                errors.username?.message ??
                'ชื่อผู้ใช้งานใช้เพื่อแสดงตัวตนในเว็บ เช่น ส่วนแสดงความคิดเห็น เป็นต้น'
              }
            />
            <TextField
              label="ชื่อและนามสกุล"
              placeholder="ชื่อและนามสกุล"
              {...register('name')}
              error={Boolean(errors.name)}
              helperText={
                errors.name?.message ??
                'ข้อมูลนี้จะถูกใช้เพื่อระบุในใบรับรอง (Certification) เมื่อผ่านหลักสูตรการเรียนรู้ ระบุคำนำหน้าตามด้วยชื่อและนามสกุล เช่น นายสมชาย สอบติด, นางสมหญิง สอบติด หรือ นางสาวสมหมาย สอบติด'
              }
            />
            <TextField
              type="email"
              label="อีเมล"
              placeholder="อีเมล"
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
            <TextField
              type="password"
              label="รหัสผ่าน (ใหม่)"
              placeholder="รหัสผ่าน (ใหม่)"
              {...register('password')}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <Button
              type="submit"
              variant="outlined"
              color="inherit"
              sx={{ mx: 'auto', width: '100%' }}
            >
              บันทึก
            </Button>
          </Stack>
        </form>
        {!profile?.emailVerified && (
          <>
            <Divider sx={{ my: 2 }}></Divider>
            <Typography variant="h5" textAlign="center">
              ส่งการยืนยันอีเมล์
            </Typography>
            <Typography component="p" mt={2}>
              หากคุณไม่ได้รับอีเมล์เพื่อยืนยันที่อยู่อีเมล์ของคุณจากเรา
              หรือคุณต้องการให้เราส่งการยืนยันทางอีเมล์อีกครั้ง
              โปรดคลิกปุ่มยืนยันอีเมล์
            </Typography>
            <Button
              type="submit"
              variant="outlined"
              color="inherit"
              sx={{ my: 2, mx: 'auto', width: '100%' }}
              onClick={verifyEmail}
            >
              ยืนยันอีเมล์
            </Button>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Profile;
