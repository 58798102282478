import { ReactNode } from 'react';

import { List } from '@mui/material';
import { School, ManageAccounts } from '@mui/icons-material';

import { LeftSidebar } from 'modules/ui/components/shared';
import AutoHideListItemButton from 'modules/ui/components/AutoHideListItemButton';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <LeftSidebar.RendererSource>
        <List sx={{ p: 0 }}>
          <AutoHideListItemButton
            icon={<ManageAccounts></ManageAccounts>}
            title="จัดการบัญชี"
            path="/dashboard/profile"
            side="left"
            selected={['/dashboard', '/dashboard/profile']}
          ></AutoHideListItemButton>
          <AutoHideListItemButton
            icon={<School></School>}
            title="คอร์สของฉัน"
            path="/dashboard/courses"
            side="left"
          ></AutoHideListItemButton>
        </List>
      </LeftSidebar.RendererSource>
      {children}
    </>
  );
};

export default Layout;
