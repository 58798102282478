import LoginRequiredGuard from 'modules/auth/components/LoginRequiredGuard';
import Profile from 'modules/dashboard/components/Profile';

const ProfilePage = () => {
  return (
    <LoginRequiredGuard>
      <Profile></Profile>
    </LoginRequiredGuard>
  );
};

export default ProfilePage;
